<template>
    <div v-if="event">
      <h2>{{ event.title }}</h2>
      <p>{{ event.location }}</p>
      <p>{{ event.date }}</p>  
    </div>
</template>

<script>

import EventService from "@/services/EventService.js";

export default {
  name: "EventDetail",
  props: ['id'],
  data() {
    return {
      event: null
    } 
  },
  created() {
    EventService.getEvent(this.id)
    .then( response => {
      this.event = response.data;
    })
    .catch(error => {
      console.log(error)
    })
  }
};

</script>